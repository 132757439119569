import LeftCard from "./LeftCard";
import "./MainService.css";
import RightCard from "./RightCard";
function MainService() {
  return (
    <section id="services">
      <>
        <h1 className="service-header">Software As a Service</h1>
        <div
          className="service-container"
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <LeftCard></LeftCard>
          <RightCard></RightCard>
        </div>
      </>
    </section>
  );
}

export default MainService;
