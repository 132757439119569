import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import './Form.css'
import { useSnackbar } from 'react-simple-snackbar'
import emailjs from '@emailjs/browser'
import { useRef } from 'react'
function ContactUsForm() {
	const form = useRef()
	const options = {
		position: 'bottom-center',
		style: {
			backgroundColor: 'midnightblue',
			border: '2px solid lightgreen',
			color: 'lightblue',
			fontFamily: 'Menlo, monospace',
			fontSize: '20px',
			textAlign: 'center',
		},
		closeStyle: {
			color: 'lightcoral',
			fontSize: '16px',
		},
	}
	const [openSnackbar, closeSnackbar] = useSnackbar(options)

	const sendEmail = (e) => {
		e.preventDefault()
		emailjs.sendForm('service_snhb5vr', 'template_olzbemr', form.current, 'mgxro5nbzN_tGmz9S').then(
			(result) => {
				openSnackbar('Thanks For contacting us we will get back with you shortly.')
			},
			(error) => {
				openSnackbar({ error })
			}
		)
	}

	return (
		<Form ref={form} className="contactUs-form" onSubmit={sendEmail}>
			<Form.Group className="mb-3" controlId="user_name">
				<Form.Label>Full Name</Form.Label>
				<Form.Control required type="text" name="user_name" placeholder="Syncom UAE" />
			</Form.Group>
			<Form.Group className="mb-3" controlId="user_email">
				<Form.Label>Email address</Form.Label>
				<Form.Control required type="email" name="user_email" placeholder="Enter your email address here" />
			</Form.Group>
			<Form.Group className="mb-3" controlId="user_email">
				<Form.Label>Phone Number</Form.Label>
				<Form.Control required type="number" name="user_number" placeholder="Enter your phone number here" />
			</Form.Group>

			<Form.Group className="mb-3" controlId="message">
				<Form.Label>Comment</Form.Label>
				<Form.Control required as="textarea" rows={6} name="message" />
			</Form.Group>

			<Button size="lg" className="contact-us-button" type="submit">
				Submit
			</Button>
		</Form>
	)
}

export default ContactUsForm
