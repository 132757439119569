import { useState } from "react";
import CardTitle from "../CardTitle";
import CareerTitle from "../CareerTitle";
import CardImage from "./CardImage";
import "./CareerCard.css";
function CareerCard() {
  const [image, setImage] = useState("./carimage.jpg");
  const setSrc = (image) => {
    setImage(image);
  };

  return (
    <>
      <div
        className="career-card-container"
        id="who"
        data-aos="flip-left"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
      >
        <CareerTitle
          disableButton={true}
          heading="Join Syncom & Make a Difference"
          subHeading="If You Are Looking For A Career In A Fast-Paced, Dynamic Environment, Syncom Is The Place For You. Check the Open Positions and kindly Send us your Resume to admin@synmcomuae.com and we will get back to you."
        ></CareerTitle>
        <CardImage
          image1="./carimage.jpg"
          image2="./carimage.jpg"
          handler={setSrc}
          src={image}
        ></CardImage>
      </div>
    </>
  );
}

export default CareerCard;
