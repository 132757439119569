import "./CareerGroupCard.css";
import CareerMainCard from "./CareerMainCard";
import CareerMainCardHeading from "./CareerMainCardHeading";

function CareerGroupCard() {
  const mainCardData = [
    {
      header: "IT Manager",
      subHeader:
        "We are looking for an IT Manager to be responsible and accountable for the smooth running of our computer systems within the limits of requirements, specifications, costs and timelines.",
      src: "https://www.herzing.edu/sites/default/files/styles/fp_960_640/public/2020-09/how-to-become-it-manager.jpg.webp?itok=v4JwR2hs",
    },
    {
      header: "Sales Manager",
      subHeader:
        "We are looking for a Sales Manager to be responsible and accountable for the smooth running of our sales department within the limits of requirements, specifications, costs and timelines.",
      src: "https://www.dealsinsight.com/wp-content/uploads/2019/11/sales-manager.jpg",
    },
    {
      header: "Account Manager",
      subHeader:
        "We are looking for an Account Manager to be responsible and accountable for the smooth running of our accounts department within the limits of requirements, specifications, costs and timelines.",
      src: "https://www.jobs.ie/job-talk/wp-content/uploads/account-manager-job-description.jpg",
    },
    // {
    //   header: "Crypto Interface",
    //   subHeader:
    //     "Our user-friendly crypto interface has been designed to open up the word of crypto to your customers.  We a re connected to a leading Crypto Exchange that gives you access to crypto wallets, deposit and withdrawal functionality for a range of major cryptocurrencies. ",
    //   src: "./card-4.svg",
    // },
    // {
    //   header: "KYC /KYB",
    //   subHeader:
    //     "Compliant customer onboarding is available using our unique KYC/KYB solution that is connected to a host of third party vendors that offer you PEP/Sanctions monitoring, Digital ID and address verification, Fraud Checks, Adverse Media and much more.",
    //   src: "./card-5.svg",
    // },
    // {
    //   header: "Transaction Monitoring",
    //   subHeader:
    //     "Monitoring your customer transactions is simple and available in real-time using our unique transaction monitoring service via trusted third-party vendors.",
    //   src: "./card-6.svg",
    // },
  ];
  return (
    <>
      <div className="group-container">
        <CareerMainCardHeading></CareerMainCardHeading>
      </div>
      <div className="group-card-container">
        {mainCardData.map((data) => (
          <CareerMainCard
            header={data.header}
            subHeader={data.subHeader}
            src={data.src}
          ></CareerMainCard>
        ))}
      </div>
    </>
  );
}

export default CareerGroupCard;
