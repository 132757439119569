import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <div className="container">
      <h1 class="mb-5 text-center">Privacy Policy</h1>
      <h4><strong>1. Introduction</strong></h4>
      <p>
        Syncom Technology (“we,” “us,” “our”) respects your privacy and is committed to protecting the personal data we process. This Privacy Policy outlines how we collect, use, store, and protect your personal data in accordance with the DIFC Data Protection Law 2020 (DIFC Law No. 5 of 2020) and relevant international standards.
      </p>
      <h4><strong>2. Data Controller Contact Information</strong></h4>
      <ul>
        <li><strong>Data Controller:</strong> Syncom Technology</li>
        <li><strong>Address:</strong> [Insert Address]</li>
        <li><strong>Email:</strong> [Insert Email]</li>
        <li><strong>Telephone:</strong> [Insert Telephone Number]</li>
      </ul>
      <h4><strong>3. Personal Data We Collect</strong></h4>
      <p>We may collect the following types of personal data:</p>
      <ul>
        <li>Employee Data: Names, job titles, work email addresses, phone numbers, and other employment-related information.</li>
        <li>Supplier Data: Names, company names, business email addresses, phone numbers, and contractual information.</li>
        <li>Client and Business Partner Data: Names, company names, business email addresses, phone numbers, and other relevant business information.</li>
      </ul>
      <h4><strong>4. Lawful Bases for Processing</strong></h4>
      <p>Our lawful bases for processing personal data include:</p>
      <ul>
        <li>Consent: When you have given clear consent for us to process your personal data for a specific purpose.</li>
        <li>Contractual Necessity: When processing is necessary for the performance of a contract to which you are a party.</li>
        <li>Legitimate Interests: When processing is necessary for our legitimate interests or those of a third party, except where such interests are overridden by your rights and interests.</li>
      </ul>
      <h4><strong>5. How We Collect Personal Data</strong></h4>
      <p>We collect personal data through various methods, including:</p>
      <ul>
        <li>Direct interactions (e.g., through forms, emails, contracts, and business interactions).</li>
        <li>Information provided by controllers or processors when acting as a sub-processor.</li>
      </ul>
      <h4><strong>6. How We Use Personal Data</strong></h4>
      <p>We use personal data for the following purposes:</p>
      <ul>
        <li>Employee Management: To manage internal communication, administrative tasks, and employment-related processes.</li>
        <li>Supplier Relationship Management: To facilitate communication and transactions with suppliers.</li>
        <li>Client Relationship Management: To manage and develop business relationships with clients and partners.</li>
      </ul>
      <h4><strong>7. How We Store Personal Data</strong></h4>
      <p>We store personal data securely in:</p>
      <ul>
        <li>Email Accounts: Company-managed email accounts for communication purposes.</li>
        <li>CRM Systems: Systems to manage relationships and transactions with clients, suppliers, and business partners.</li>
        <li>Cloud Storage: Secure cloud-based storage solutions with appropriate access controls.</li>
      </ul>
      <h4><strong>8. Data Retention</strong></h4>
      <p>
        We retain personal data in accordance with our internal retention policies. These policies ensure that data is kept only as long as necessary to fulfill the purposes for which it was collected and comply with legal and regulatory requirements. Data that is no longer needed is securely deleted.
      </p>
      <h4><strong>9. Data Sharing</strong></h4>
      <p>We may share personal data with:</p>
      <ul>
        <li>Internal Stakeholders: Such as HR, IT, and administration departments for operational purposes.</li>
        <li>External Service Providers: Such as cloud storage and CRM system providers for efficient business operations.</li>
        <li>Suppliers, Clients, and Business Partners: As necessary to maintain and fulfill business relationships and contractual obligations.</li>
      </ul>
      <h4><strong>10. Data Protection Measures</strong></h4>
      <p>
      We implement the following measures to protect personal data:
      </p>
      <ul>
        <li>Encryption: All stored and shared data is encrypted to prevent unauthorized access.</li>
        <li>Access Controls: Strict access control measures to ensure only authorized personnel can access personal data.</li>
        <li>Regular Audits: Conduct regular audits to ensure compliance with data protection regulations.</li>
        <li>Data Processing Agreements (DPAs): Contractual agreements with third-party processors to ensure compliance with data protection laws.</li>
      </ul>
      <h4><strong>11. Your Rights</strong></h4>
      <p>
      Under the DIFC Data Protection Law 2020, you have the following rights:
      </p>
      <ul>
        <li>Access: The right to request access to your personal data.</li>
        <li>Correction: The right to request correction of inaccurate or incomplete personal data.</li>
        <li>Deletion: The right to request deletion of your personal data.</li>
        <li>Objection: The right to object to the processing of your personal data.</li>
        <li>Restriction: The right to request restriction of processing of your personal data.</li>
        <li>Portability: The right to request transfer of your personal data to another controller.</li>
      </ul>
      <p>To exercise your rights, please contact us at [Insert Contact Email].</p>
      <h4><strong>12. Changes to This Privacy Policy</strong></h4>
      <p>
        We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website and updating the effective date.
      </p>
      <h4><strong>13. Contact Us</strong></h4>
      <p>If you have any questions about this Privacy Policy or our data protection practices, please contact us at:</p>
      <ul>
        <li><strong>Email:</strong> [Insert Email]</li>
        <li><strong>Telephone:</strong> [Insert Telephone Number]</li>
        <li><strong>Address:</strong> [Insert Address]</li>
      </ul>
      <h4><strong>14. Governing Law</strong></h4>
      <p>
        This Privacy Policy is governed by the laws of the Dubai International Financial Centre (DIFC).
      </p>
    </div>
  );
};

export default Privacy;
