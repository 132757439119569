import React from "react";
import CareerCard from "../header/cards/CareerCard";
import CareerGroupCard from "../header/careergroupCards/CareerGroupCard";
import "./Career.css";
const Career = () => {
  return (
    <div>
      {/* <div className="career-container-text">
        <h1 className="career-h">Careers</h1>
      </div> */}
      <CareerCard></CareerCard>
      <CareerGroupCard></CareerGroupCard>
    </div>
  );
};

export default Career;
