import "./Footer.css";
import { CDBFooter, CDBFooterLink, CDBBox, CDBBtn, CDBIcon } from "cdbreact";
function ContactUsFooter() {
  return (
    <CDBFooter className="shadow">
      <CDBBox
        display="flex"
        flex="column"
        className="mx-auto py-5"
        style={{ width: "90%" }}
      >
        <CDBBox display="flex" justifyContent="between" className="flex-wrap">
          <CDBBox>
            <a href="/" className="d-flex align-items-center p-0 text-dark">
              <img
                alt="logo"
                src="https://syncomuae.com/fnal.png"
                width="200px"
              />
              {/* <span className="ml-3 h5 font-weight-bold">Devwares</span> */}
            </a>
            <p className="my-3" style={{ width: "250px" }}>
              We offer a unique fintech platform that allows you to create and
              launch your own fully branded banking solution as an app or on the
              web.
            </p>
            <CDBBox display="flex" className="mt-4">
              <CDBBtn>
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn className="mx-3">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </CDBBox>
          </CDBBox>
          <CDBBox>
            <p
              className="h5 mb-4"
              style={{ fontWeight: "600", color: "#36d9d9" }}
            >
              About Us
            </p>
            <CDBBox
              flex="column"
              style={{
                cursor: "pointer",
                padding: "0",
              }}
            >
              <CDBFooterLink href="/privacy-policy">Privacy Policy</CDBFooterLink>
              <CDBFooterLink href="/">Terms and conditions</CDBFooterLink>
              <CDBFooterLink href="/career">Careers</CDBFooterLink>
              <CDBFooterLink href="/#contactUs">Contact Us</CDBFooterLink>
            </CDBBox>
          </CDBBox>
          <CDBBox>
            <p
              className="h5 mb-4"
              style={{ fontWeight: "600", color: "#36d9d9" }}
            >
              Help
            </p>
            <CDBBox flex="column" style={{ cursor: "pointer", padding: "0" }}>
              <CDBFooterLink href="mailto:asim@syncomuae.com">
                Support
              </CDBFooterLink>
              {/* <CDBFooterLink href="/">Email</CDBFooterLink> */}
              {/* <CDBFooterLink href="#mission">What we Do</CDBFooterLink> */}
            </CDBBox>
          </CDBBox>
          {/* <CDBBox>
			  <p className="h5 mb-4" style={{ fontWeight: '600' }}>
				Products
			  </p>
			  <CDBBox flex="column" style={{ cursor: 'pointer', padding: '0' }}>
				<CDBFooterLink href="/">Windframe</CDBFooterLink>
				<CDBFooterLink href="/">Loop</CDBFooterLink>
				<CDBFooterLink href="/">Contrast</CDBFooterLink>
			  </CDBBox>
			</CDBBox> */}
      <div className="mt-5">
        <div style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
        <small> Disclaimer:</small>
        </div>
        <small>
        The author assumes no responsibility or liability for any errors or omissions in the content of this site. The information contained in this site is provided on an "as is" basis with no guarantees of completeness, accuracy, usefulness, or timeliness.
        </small>
      </div>

      <div className="mt-2">
        <div style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
          <small>Note:</small>
          
        </div>
       <small>
       Syncom Technology Ltd only provides core banking software solutions and does not provide any money service or banking services it selves .
       </small>
      </div>

        </CDBBox>
        <small className="text-center mt-5">
          &copy; Syncom Technology  Ltd, 2022. All rights reserved.
        </small>
      </CDBBox>
    </CDBFooter>
  );
}

export default ContactUsFooter;
