import './RightCard.css'

function RightCard({ src }) {
	return (
		<>
			<div className="card-right-image-container">
				<img alt="" className="card-right-image" src="./weimage.png"></img>
			</div>
		</>
	)
}

export default RightCard
