import "./App.css";
import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/header/Header";
import FirstCard from "./components/header/cards/FirstCard";
import SecondCard from "./components/header/cards/SecondCard";
import ThirdCard from "./components/header/cards/ThirdCard";
import GroupCard from "./components/header/groupCards/GroupCard";
import Demo from "./components/demo/Demo";
import MainService from "./components/services/MainService";
import ContactUs from "./components/contactus/ContactUs";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "@fontsource/questrial";
import SnackbarProvider from "react-simple-snackbar";
import Why from "./components/why-us/Why";
import Home from "./components/home/Home";
import Career from "./components/career/Career";
import Account from "./components/account/Account";
import Kyc from "./components/kyc/Kyc";
import ContactUsFooter from "./components/footer/Footer";
import Privacy from "./components/privacy/privacy";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <BrowserRouter>
        <SnackbarProvider>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home></Home>}></Route>
            <Route exact path="/career" element={<Career></Career>}></Route>
            <Route exact path="/account" element={<Account></Account>}></Route>
            <Route exact path="/kyc" element={<Kyc></Kyc>}></Route>
            <Route exact path="/privacy-policy" element={<Privacy></Privacy>}></Route>
          </Routes>
          <ContactUsFooter></ContactUsFooter>
        </SnackbarProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
