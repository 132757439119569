import ContactUsFooter from "../footer/Footer";
import "./ContactUs.css";
import ContactUsForm from "./Form";

function ContactUs() {
  return (
    <>
      <div
        className="contact-container"
        data-aos="flip-left"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
        id="contactUs"
      >
        <div className="contact-form-container">
          <h1 className="contact-title">Contact Us</h1>
          <p className="contact-subtitle">Contact our team for more Details</p>
          <ContactUsForm></ContactUsForm>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
