import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./Kyc.css";
import KycData from "./KycData";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
const Kyc = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="account-container">
      <div className="account-text-container">
        <p className="account-p">KYC/KFB</p>
        <h1 className="account-h">
          Syncom Technologies Ltd, will be launching and further developing its
          state-of-the-art cloud based digital onboarding, KYC control. Our
          innovative SAAS will provide one stop solution to the financial
          services providers, ranging from digital onboarding, KYC Control (Four
          eyes), MLRO (Business Methodology), Risk Management, Banking
          (Ledgers), Expense Management, Post onboarding Transaction monitoring
          and comprehensive reporting. Most of the Software providers are only
          IT experts while Syncom Technology Ltd took assistance, and will keep
          taking assistance when required, from the leading financial regulatory
          experts (MLROs, CFOs etc.) to come up with a solution which not only
          covers the IT needs of the institution but also satisfies the
          regulatory requirements. The flexible risk management module will
          allow its user to implement the KYC policies which is aligned to
          everchanging regulatory requirement and AML laws. It will allow the
          institutions to reduce its reliance on the back-office staff and will
          shift more control towards the actual decision makers like CFOs, MLROs
          and Directors. It will not only enhance monitoring but will provide a
          better insight of the business and associated risk
        </h1>

        <Button size="lg" className="kyc-button" onClick={handleShow}>
          Request a Demo
        </Button>
      </div>
      <KycData handleShow={handleShow}></KycData>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <img src="fnal.png" width="150" height="50"></img>
          {/* <Modal.Title style={{ paddingLeft: "110px", color: "#36d9d9" }}>
            Request A Demo
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Header>
          <Modal.Title className="modal-title-acc">Request A Demo</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your First Name"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your Last Name"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter your Mobile Number Here"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className="kyc-button"
            variant="primary"
            onClick={handleClose}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Kyc;
