import { Button } from 'react-bootstrap'
import './Demo.css'

function Demo() {
	return (
		<>
			<div className="card-demo-container" data-aos="flip-left" data-aos-offset="200" data-aos-easing="ease-in-sine">
			<div className='my-hero'>
				<h1 className="card-demo-title">Request a Demo</h1>
				<p className="card-demo-subtitle">Contact our team for more Details</p>
				<a href="#contactUs">
					<Button size="lg" variant="danger" className="card-demo-button">
						Contact Us
					</Button>
				</a>
				</div>
			</div>
		</>
	)
}

export default Demo
