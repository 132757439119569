import { Button } from "react-bootstrap";
import "./MainCard.css";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
function MainCard({ header, subHeader, src, link, btntext }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div
        className="main-main-card-container"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
      >
        <img alt="Card" src={src}></img>
        <h1 className="mini-card-header">{header}</h1>
        <h4 className="mini-card-subHeader">{subHeader}</h4>
        <a href={link} onClick={link === "javascript:" && handleShow}>
          <Button size="lg" className="main-card-button">
            {btntext}
          </Button>
        </a>
      </div>
      <Modal className="coming-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <img src="fnal.png" width="150" height="50"></img>
          {/* <Modal.Title style={{ paddingLeft: "110px", color: "#36d9d9" }}>
            Request A Demo
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Header>
          <Modal.Title className="modal-title-acc" style={{ color: "#36d9d9" }}>
            Launching Soon
          </Modal.Title>
        </Modal.Header>

        {/* <Modal.Body className="modal-body">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your First Name"
                  autoFocus
                  name="first_name"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Last Name"
                  autoFocus
                  name="last_name"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  autoFocus
                  name="user_email"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Mobile Number Here"
                  autoFocus
                  name="user_number"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} name="message" required />
              </Form.Group>
              <Button variant="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className="new-account-card-modal-button"
                variant="primary"
                type="submit"
              >
                Send Request
              </Button>
            </Form>
          </Modal.Body> */}
        {/* <Modal.Footer></Modal.Footer> */}
      </Modal>
    </>
  );
}

export default MainCard;
