import { Button } from "react-bootstrap";
import "./CardTitle.css";

function CareerTitle({
  heading,
  subHeading,
  disableButton = false,
  miniTitle = false,
}) {
  return (
    <>
      <div className="card-container">
        {miniTitle ? (
          <h1 className="card-mini-title">{heading}</h1>
        ) : (
          <h1 className="card-title">{heading}</h1>
        )}{" "}
        <p className="card-subtitle">{subHeading}</p>
        {!disableButton && (
          <a href="#contactUs">
            <Button size="lg" className="card-button">
              Contact Us
            </Button>
          </a>
        )}
      </div>
    </>
  );
}

export default CareerTitle;
