import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./Header.css";
function Header() {
  return (
    <>
      <div className="nav-container">
        <Navbar
          collapseOnSelect
          variant="light"
          key="xl"
          bg="white"
          expand="xl"
          sticky
          fixed="top"
        >
          <Container fluid>
            <Navbar.Brand href="/" className="navbar-logo">
              <img
                src="/fnal.png"
                className="navbar-logo-image"
                alt="logo"
              ></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
            <Navbar.Offcanvas
              enforceFocus={false}
              id={`offcanvasNavbar-expand-xl`}
              aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                  SYNCOM
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3 nav-links">
                  <Nav.Link className="nav-link" href="/#who">
                    Who We Are
                  </Nav.Link>
                  <Nav.Link className="nav-link" href="/#mission">
                    Mission
                  </Nav.Link>
                  <Nav.Link className="nav-link" href="/#Bas">
                    Baas
                  </Nav.Link>
                  <Nav.Link className="nav-link" href="/#services">
                    Saas
                  </Nav.Link>
                </Nav>
                <div className="d-flex">
                  <a href="/#contactUs" className="a-con">
                    <Button size="md" variant="outline" className="nav-button">
                      Contact Us
                    </Button>
                  </a>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default Header;
