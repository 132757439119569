import React from "react";
import ContactUs from "../contactus/ContactUs";
import Demo from "../demo/Demo";
import FirstCard from "../header/cards/FirstCard";
import SecondCard from "../header/cards/SecondCard";
import ThirdCard from "../header/cards/ThirdCard";
import GroupCard from "../header/groupCards/GroupCard";

import MainService from "../services/MainService";

const Home = () => {
  return (
    <>
      <FirstCard></FirstCard>
      <SecondCard></SecondCard>
      {/* <Why></Why> */}
      <ThirdCard></ThirdCard>
      <GroupCard></GroupCard>

      <Demo></Demo>
      <MainService></MainService>
      <ContactUs></ContactUs>
    </>
  );
};

export default Home;
