import "./MainCardHeading.css";

function MainCardHeading({ subHeading, heading }) {
  return (
    <>
      <div className="main-card-title-container">
        <h1 className="heading-1">Banking as a Service</h1>
        <h3 className="heading-2">Have Your Bank Within Weeks</h3>

        <h4 className="heading-3">
          Connect to a range of licenses and manager your clients.
        </h4>
      </div>
    </>
  );
}

export default MainCardHeading;
