import CardMiniTitle from "./CardMiniTitle";
import "./ThirdCard.css";
function ThirdCard() {
  return (
    <section id="mission">
      <>
        <div
          className="third-card-container"
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <CardMiniTitle
            heading="Mission"
            subHeading="We have developed an extensive fintech-as-a-service platform for financial and non-financial organisations.  We aim to empower our customers to quicky launch a fintech proposition with our advanced and fully customisable digital platform.
					Our platform allows customers to connect to the marketplace via our API and has been built with cost, efficiency and speed in mind, enabling fast product development and launch."
          ></CardMiniTitle>

          <hr className="solid"></hr>
          <CardMiniTitle subHeading="We continually invest in our technology and people by looking for ways to improve our proposition and offering to our customers.  We only work with the best in the industry in order to provide what we believe is the best product in the industry."></CardMiniTitle>
        </div>
      </>
    </section>
  );
}

export default ThirdCard;
