import { useState } from "react";
import CardTitle from "../CardTitle";
import CardImage from "./CardImage";
import "./FirstCard.css";
function FirstCard() {
  const [image, setImage] = useState(
    "https://img.freepik.com/free-vector/web-page-visualization-protocol-procedure-dynamic-software-workflow-full-stack-development-markup-administrate-system-driver-shared-memory-vector-isolated-concept-metaphor-illustration_335657-4299.jpg?w=740&t=st=1666706244~exp=1666706844~hmac=b17cc44d3705d4479e82f5d8ad7db7dba0a12e897fd4955881cc053b7df43dfe"
  );
  const setSrc = (image) => {
    setImage(image);
  };

  return (
    <>
      <section id="who">
        <div
          className="first-card-container"
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
        >
          <CardTitle
            heading="We are SYNCOM"
            subHeading="Our corporate embedded finance system handles everything, so you don't have to build anything yourself—unless you choose to."
            isMiniHeading = {true}
            miniHeading="Everything from a single platform."
          ></CardTitle>
          <CardImage
            image1="https://img.freepik.com/free-vector/web-page-visualization-protocol-procedure-dynamic-software-workflow-full-stack-development-markup-administrate-system-driver-shared-memory-vector-isolated-concept-metaphor-illustration_335657-4299.jpg?w=740&t=st=1666706244~exp=1666706844~hmac=b17cc44d3705d4479e82f5d8ad7db7dba0a12e897fd4955881cc053b7df43dfe"
            image2="https://img.freepik.com/free-vector/sales-growth-commerce-analytics-profit-analysis-business-analyst-marketing-plan-marketologist-holding-clipboard-cartoon-character-vector-isolated-concept-metaphor-illustration_335657-2756.jpg?w=740&t=st=1666706369~exp=1666706969~hmac=5568ead555461d1f1b304fcdf91ce73c49f7ef69ff2ae8f88be268483400b21b"
            handler={setSrc}
            src={image}
          ></CardImage>
        </div>
      </section>
    </>
  );
}

export default FirstCard;
