import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./Account.css";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useSnackbar } from "react-simple-snackbar";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
const Account = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const form = useRef();
  const options = {
    position: "bottom-center",
    style: {
      backgroundColor: "midnightblue",
      border: "2px solid lightgreen",
      color: "lightblue",
      fontFamily: "Menlo, monospace",
      fontSize: "20px",
      textAlign: "center",
    },
    closeStyle: {
      color: "lightcoral",

      fontSize: "16px",
    },
  };
  const [openSnackbar, closeSnackbar] = useSnackbar(options);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_snhb5vr",
        "template_olzbemr",
        form.current,
        "mgxro5nbzN_tGmz9S"
      )
      .then(
        (result) => {
          openSnackbar(
            "Thanks For contacting us we will get back with you shortly."
          );
          handleClose();
        },
        (error) => {
          openSnackbar({ error });
        }
      );
  };
  return (
    <div className="new-account-container">
      <div className="new-account-text-container">
        <p className="new-account-p">A Complete Payment Platform</p>
        <h1 className="new-account-h">
          Use our platform to create and launch your own fully branded banking
          or finance solution as an app or on the web! Create the perfect
          solution for your users on one comprehensive platform. Everything runs
          on our primary configuration and management system, so you don’t need
          to build anything yourself (unless you want to).
        </h1>
        <img className="diag-img" src="./diag.jpg"></img>

        <Button
          size="lg"
          className="new-account-card-button"
          onClick={handleShow}
        >
          Request a Demo
        </Button>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <img src="fnal.png" width="150" height="50"></img>
          {/* <Modal.Title style={{ paddingLeft: "110px", color: "#36d9d9" }}>
            Request A Demo
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Header>
          <Modal.Title className="modal-title-acc">Request A Demo</Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body">
          <Form ref={form} onSubmit={sendEmail}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your First Name"
                autoFocus
                name="first_name"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your Last Name"
                autoFocus
                name="last_name"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="name@example.com"
                autoFocus
                name="user_email"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter your Mobile Number Here"
                autoFocus
                name="user_number"
                required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} name="message" required />
            </Form.Group>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="new-account-card-modal-button"
              variant="primary"
              type="submit"
            >
              Send Request
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <div></div>
    </div>
  );
};

export default Account;
