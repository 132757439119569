import React from "react";
import { Button } from "react-bootstrap";
import "./KycData.css";
const KycData = ({ handleShow }) => {
  return (
    <div>
      <div className="upvcc-container">
        <div
          data-aos="flip-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          className="upvcc-imgcont"
        >
          <img src="./kyc1.png"></img>
        </div>
        <div
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          delay="100"
          className="testdiv"
        >
          <h1 style={{ color: "#36d9d9", textAlign: "left" }}>
            {" "}
            Digital Onboarding
          </h1>
          <p style={{ fontFamily: "Barlow sans-serif" }}>
            A smart system where a company can apply for the account using
            interactive onboarding form. The requirements of the needed KYC
            documents are generated by the system as per the provided data.
            Customized Onboarding Journey: System is designed to provide the
            separate onboarding journeys based on the input.
            <li>
              User’s journey is customized as per selected company formation.
            </li>
            <li>
              In line to the FATF guide and local laws certain geographical
              areas can be filtered out.
            </li>
            <li>
              Customers can be filtered out or provided specific journeys based
              on the transaction volume
            </li>
          </p>
        </div>
      </div>
      <div className="upvcc-container">
        <div
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          delay="100"
          className="testdiv2"
        >
          <h1 style={{ color: "#36d9d9", textAlign: "left" }}>
            {" "}
            Interactive Data Collection.
          </h1>
          <p style={{ fontFamily: "Barlow sans-serif" }}>
            Data collection procedure is very interactive in the meantime very
            comprehensive, covering all the basis and meeting the regulatory
            requirements of good practices for financial services providers.
          </p>
        </div>
        <div
          data-aos="flip-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          className="upvcc-imgcont"
        >
          <img src="./kyc2.png"></img>
        </div>
      </div>
      <div className="upvcc-container">
        <div
          data-aos="flip-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          className="upvcc-imgcont"
        >
          <img src="./kyc3.png"></img>
        </div>
        <div
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          delay="100"
          className="testdiv"
        >
          <h1 style={{ color: "#36d9d9", textAlign: "left" }}>
            {" "}
            Ultimate Beneficiary Details
          </h1>
          <p style={{ fontFamily: "Barlow sans-serif" }}>
            For the financial services provider it is vital to know who the
            actual beneficiary of the company is. Sometimes a company is buried
            under the layers of the owning companies. This interactive form
            enables the service provider to unveil the layers of such UBO and
            get to know the actual UBO with home relationship to be established.
          </p>
        </div>
      </div>
      <div className="upvcc-container">
        <div
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          className="testdiv2"
          delay="100"
        >
          <h1 style={{ color: "#36d9d9", textAlign: "left" }}>
            Company Documents for KYC
          </h1>
          <p style={{ fontFamily: "Barlow sans-serif" }}>
            Based on the filled in information a set of requirements for
            applicant company’s KYC is raised and a very graphical, easy to use
            and simple to understand documents uploading module allows the
            customers to provide the needed documents
          </p>
        </div>
        <div
          data-aos="flip-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          className="upvcc-imgcont"
        >
          <img src="./kyc4.png"></img>
        </div>
      </div>
      <div className="upvcc-container">
        <div
          data-aos="flip-left"
          data-aos-offset="200"
          data-aos-easing="ease-in-sine"
          className="upvcc-imgcont"
        >
          <img src="./kyc5.png"></img>
        </div>
        <div
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          delay="100"
          className="testdiv"
        >
          <h1 style={{ color: "#36d9d9", textAlign: "left" }}>
            Individual Documents for KYC:
          </h1>
          <p style={{ fontFamily: "Barlow sans-serif" }}>
            This form is basically collecting three vital information
            <li>Identification of the individual</li>
            <li>Residence proof of the individual.</li>
            <li>Selfie of the individual</li>A comprehensive module tracking the
            customer from lead to going through the KYC process and resulting in
            success or failure. Following the good practice, a four eyes KYC
            process is setup where both level of KYC is performed by the
            independent induvial reducing the chance of influence and
            wrongdoing.
          </p>
        </div>
      </div>
      <div className="upvcc-container">
        <div
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          delay="100"
          className="testdiv2"
        >
          <h1 style={{ color: "#36d9d9", textAlign: "left" }}> Kyc Level 1</h1>
          <p style={{ fontFamily: "Barlow sans-serif" }}>
            KYC Level 1 is focused on physical verification of the documents and
            ensuring that all the needed documents are provided and that the
            documents are valid and are as per the guideline with respect to the
            celerity, completeness, and type. If applicant company need to
            provide any additional documents, the further document requirement
            can be raised, and user can log back to the application and provide
            the needed documents
          </p>
        </div>
        <div
          data-aos="flip-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          className="upvcc-imgcont"
        >
          <img src="./kyc7.png"></img>
        </div>
      </div>
      <div className="upvcc-container">
        <div
          data-aos="flip-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          className="upvcc-imgcont"
        >
          <img src="./kyc6.png"></img>
        </div>
        <div
          data-aos="fade-left"
          data-aos-offset="100"
          data-aos-easing="ease-in-sine"
          delay="100"
          className="testdiv2"
        >
          <h1 style={{ color: "#36d9d9", textAlign: "left" }}> Services</h1>
          <p style={{ fontFamily: "Barlow sans-serif" }}>
            <li>ID Verification</li>
            <li>PEP & Sanction</li>
            <li>Document authenticity</li>
            <li>Credit Check</li>
            <li>Adverse Media Coverage Check</li>
            <li>Fraud Check</li>
            <li>Selfie Verification</li>
            <li>Mobile, Email and address verification etc.</li>
          </p>
        </div>
      </div>
      <div className="last-kyc-button">
        <Button size="lg" className="kyc-button" onClick={handleShow}>
          Request a Demo
        </Button>
      </div>
    </div>
  );
};

export default KycData;
