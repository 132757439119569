import './CardMiniTitle.css'

function CardMiniTitle({ subHeading, heading }) {
	return (
		<>
			<div className="card-mini-container">
				<h1 className="card-mini-title">{heading}</h1>
				<p className="card-mini-subtitle">{subHeading}</p>
			</div>
		</>
	)
}

export default CardMiniTitle
