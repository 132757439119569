import { useState } from "react";
import CardImage from "./CardImage";
import "./SecondCard.css";
function SecondCard() {
  const [image, setImage] = useState(
    "https://img.freepik.com/free-vector/digital-wallet-abstract-concept-illustration_335657-3896.jpg"
  );
  const setSrc = (image) => {
    setImage(image);
  };
  return (
    <>
      <div className="second-card-container">
        <CardImage
          src={image}
          handler={setSrc}
          image1="https://img.freepik.com/free-vector/digital-wallet-abstract-concept-illustration_335657-3896.jpg"
          image2="https://img.freepik.com/free-vector/employees-cv-candidates-resume-corporate-workers-students-id-isolate-flat-design-element-job-applications-avatars-personal-information-concept-illustration_335657-1661.jpg?w=740&t=st=1666706517~exp=1666707117~hmac=d379b3f5ec6f354911336b5d7ce9fc96cf259a229bd67f80eb09cd38f5839c5f"
        ></CardImage>
        <div className="card-second-container">
          <h1 className="card-second-title">Who We Are</h1>
          <p className="card-second-subtitle">
          We have created a comprehensive fintech-as-a-service platform tailored for both financial and non-financial organizations. This platform empowers our clients to swiftly introduce fintech solutions using our sophisticated, fully customizable, digital bank-grade technology. It also allows for seamless integration with our clients' own fintech products and access to our extensive marketplace of global partners.
            <br></br>
            <br></br>
            Designed with cost-effectiveness, efficiency, and speed at its core, our platform facilitates rapid product development and deployment.
          </p>
        </div>
      </div>
    </>
  );
}

export default SecondCard;
