import "./CareerMainCardHeading.css";

function CareerMainCardHeading({ subHeading, heading }) {
  return (
    <>
      <div className="main-card-title-container">
        <h3 className="heading-1">Careers</h3>
        <h1 className="heading-2">Open Positions</h1>
        <h4 className="heading-3">
          We are always looking for talented people to join our team.
        </h4>
      </div>
    </>
  );
}

export default CareerMainCardHeading;
