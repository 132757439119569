import './LeftCard.css'

function LeftCard() {
	return (
		<>
			<div className="card-left-container">
				<h1 className="card-left-header">Power your Fintech business within weeks</h1>
				<p className="card-left-subheader">
					Syncom’s Software as a Service (SaaS) platform has been designed so that you have one system that can do
					absolutely everything you need it do..
				</p>
				<h1 className="card-left-header">Our Solution Offers</h1>
				<ul className="left-list">
					<li className="left-list-item">
						{' '}
						Our SAAS platform offers you the opportunity to create and customise your own solution{' '}
					</li>
					<li className="left-list-item"> Being able to connect with fully licensed and compliant BaaS providers </li>
					<li className="left-list-item"> Offer payment solutions from day one</li>
					<li className="left-list-item">Working with the best in the business </li>
				</ul>
			</div>
		</>
	)
}

export default LeftCard
