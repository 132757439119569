import "./GroupCard.css";
import MainCard from "./MainCard";
import MainCardHeading from "./MainCardHeading";

function GroupCard() {
  const mainCardData = [
    {
      header: "Accounts (IBAN)",
      subHeader:
        "Create accounts for corporate and personal customers.  Manage account transactions for multiple accounts.  Participate in Faster Payments, SEPA and SWIFT payment networks with multiple routes globally. ",
      src: "./card-1.svg",
      link: "/account",
      btntext: "Learn More",
    },
    {
      header: "Cards",
      subHeader:
        "Issue pre-paid cards in your own brand and unique style.Available in global markets including the UK and Europe. We are working on all kinds of platforms ",
      src: "./card-2.svg",
      link: "javascript:",
      btntext: "Coming Soon",
    },
    {
      header: "Foreign Exchange",
      subHeader:
        "We give you access to over 100 currencies and offer some of the most competitive rates in the marketplace through our vast network of liquidity providers.",
      src: "./card-3.svg",
      link: "javascript:",
      btntext: "Coming Soon",
    },
    {
      header: "Crypto Interface",
      subHeader:
        "Our user-friendly crypto interface has been designed to open up the word of crypto to your customers.  We a re connected to a leading Crypto Exchange that gives you access to crypto wallets, deposit and withdrawal functionality for a range of major cryptocurrencies. ",
      src: "./card-4.svg",
      link: "javascript:",
      btntext: "Coming Soon",
    },
    {
      header: "KYC /KYB",
      subHeader:
        "Compliant customer onboarding is available using our unique KYC/KYB solution that is connected to a host of third party vendors that offer you PEP/Sanctions monitoring, Digital ID and address verification, Fraud Checks, Adverse Media and much more.",
      src: "./card-5.svg",
      link: "/kyc",
      btntext: "Learn More",
    },
    {
      header: "Transaction Monitoring",
      subHeader:
        "Monitoring your customer transactions is simple and available in real-time using our unique transaction monitoring service via trusted third-party vendors.",
      src: "./card-6.svg",
      link: "javascript:",
      btntext: "Coming Soon",
    },
  ];
  return (
    <section id="Bas">
      <>
        <div className="group-container">
          <MainCardHeading></MainCardHeading>
        </div>
        <div className="group-card-container">
          {mainCardData.map((data) => (
            <MainCard
              header={data.header}
              subHeader={data.subHeader}
              src={data.src}
              link={data.link}
              btntext={data.btntext}
            ></MainCard>
          ))}
        </div>
      </>
    </section>
  );
}

export default GroupCard;
