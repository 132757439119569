import './CardImage.css'

function CardImage({ src,handler,image1,image2 }) {
	return (
		<>
			<div  className="card-image-container">
				<img  onMouseOver={()=>handler(image2)} onMouseOut={()=>handler(image1)}alt="" className="card-image" src={src}></img>
			</div>
		</>
	)
}

export default CardImage
