import "./CareerMainCard.css";

function CareerMainCard({ header, subHeader, src }) {
  return (
    <>
      <div
        className="main-card-container"
        id="Bas"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-easing="ease-in-sine"
      >
        <img className="main-card-img" alt="Card" src={src}></img>
        <h1 className="mini-card-header">{header}</h1>
        <h4 className="mini-card-subHeader">{subHeader}</h4>
      </div>
    </>
  );
}

export default CareerMainCard;
